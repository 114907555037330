import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Icon } from '@iconify/react';

import {
  Box, Typography, Button,
  List, ListSubheader, ListItem, ListItemText, Chip,
  Paper, Stack, SwipeableDrawer
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: grey[100],
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.background.default,
  }),
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: grey[300],
  ...theme.applyStyles('dark', {
    backgroundColor: grey[800],
  }),
}));

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: grey[500],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
  ...theme.applyStyles('dark', {
    backgroundColor: grey[900],
  }),
}));

function SwipeableEdgeDrawer(props) {
  const { window } = props;
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleClickNextStep = () => {
    if (props.activeStep !== 3 && props.activeStep !== 4) {
      props.handleClickNextStep()
    }
    
    setOpen(false);
  }

  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined;

  const renderSelectedServices = () => {
    if (props.selectedServices.length !== 0) {
      // services
      return props.selectedServices.map((service, index) => {
        return (
          <ListItem
            key={index}
            secondaryAction={service.bundleName !== undefined ?
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => props.removeSelectedServiceBundle(service, index)}
                startIcon={<DeleteIcon />}
              >
                Bundle
              </Button>
              :
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => props.removeSelectedService(service, index)}
                startIcon={<DeleteIcon />}
              >
                Service
              </Button>
            }
          >
            <ListItemText
              primary={
                <>{service.onlineBookingName.trim() !== '' ? service.onlineBookingName : service.name} {service.bundleName !== undefined && <Chip icon={<Icon icon="fluent:box-multiple-20-regular" />} label="Bundle" size="small" component="span" />}</>
              }
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </ListItem>
        )
      })
    } else if (props.selectedClass.length !== 0) {
      // class
      return props.selectedClass.map((c, index) => {
        return (
          <ListItem
            key={index}
            secondaryAction={
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => props.removeSelectedClass(c, index)}
                startIcon={<DeleteIcon />}
              >
                Remove Class
              </Button>
            }
          >
            <ListItemText primary={c.className} />
          </ListItem>
        )
      })
    } else {
      return null
    }
  }

  const renderSelectedServicesList = () => {
    return (
      <Paper variant="outlined">
        <List>
          {renderSelectedServices()}
          <ListItem>
            <ListItemText
              primary={'Expected Duration: ' + props.expectedDuration + ' minutes'}
              primaryTypographyProps={{ variant: 'subtitle2' }}
            />
          </ListItem>
        </List>
      </Paper>
    )
  }

  const renderTitle = () => {
    if (props.selectedClass.length === 1) {
      return '1 class selected'
    }

    if (props.selectedServices.length === 1) {
      return '1 service selected'
    } else {
      return `${props.selectedServices.length} services selected`
    }
  }

  return (
    <Root>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0
          }}
        >
          <Puller />
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              pr: 2
            }}
          >
            <Typography sx={{ p: 2 }} variant={open ? 'h6' : 'body1'}>
              {renderTitle()}
            </Typography>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={open ? toggleDrawer(false) : toggleDrawer(true)}
              endIcon={open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              sx={{
                pointerEvents: "all"
              }}
            >
              {open ? 'Hide Details' : 'View Details'}
            </Button>
          </Stack>
        </StyledBox>
        <StyledBox sx={{ px: 2, pb: 2, pt: 1, height: '100%', overflow: 'auto' }}>
          {/* <Skeleton variant="rectangular" height="100%" /> */}
          {renderSelectedServicesList()}
          <Box sx={{ pt: 1, display: 'flex', justifyContent: 'end' }}>
            <Button variant="contained" onClick={handleClickNextStep}>Next Step</Button>
          </Box>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;