import React from 'react';

import { Icon } from '@iconify/react';
import { styled } from '@mui/material/styles';
import {
  Box, Grid2 as Grid, Typography, Chip, Stack, Badge, Divider,
  List, ListItem, ListItemText, ButtonGroup, Button,
  Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: 0,
  borderRight: 0,
  borderBottom: 0,
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    display: 'block'
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function CategoryCard(props) {
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const renderServiceTags = (tagIds) => {
    return tagIds.map(tagId => {
      const tag = props.serviceTags.find(tag => tag._id === tagId);

      return (
        <Grid key={tag._id}>
          <Chip
            label={tag.tagName}
            style={{ backgroundColor: tag.tagColor, color: tag.textColor }}
            size="small"
          />
        </Grid>
      )
    })
  }

  const renderService = () => {
    return props.services.map((service, index) => {
      return (
        <React.Fragment key={service._id}>
          <ListItem
            key={service._id}
            secondaryAction={isBigScreen &&
              <Badge
                badgeContent={props.selectedServices.filter(s => s._id === service._id).length}
                color="primary"
              >
                <ButtonGroup size="small">
                  <Button
                    edge="end"
                    color="error"
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: 0 }}
                    onClick={() => props.handleRemoveService(service._id)}
                    disabled={props.selectedServices.filter(s => s._id === service._id).length === 0}
                  >
                    <RemoveIcon />
                  </Button>
                  <Button edge="end" color="primary" variant="outlined" size="small" sx={{ minWidth: 0 }} onClick={() => props.handleSelectService(service._id)}>
                    <AddIcon />
                  </Button>
                </ButtonGroup>
              </Badge>
            }
          >
            <ListItemText
              sx={{ mr: 8 }}
              primary={props.onlineBookings === true && service.onlineBookingName.trim() !== '' ? service.onlineBookingName : service.name}
              secondaryTypographyProps={{ component: 'div' }}
              secondary={props.onlineBookings === true &&
                <Stack spacing={0.25}>
                  {service.onlineBookingDescription !== undefined && service.onlineBookingDescription.trim() !== '' &&
                    <Typography component="div" variant="caption">
                      <i>{service.onlineBookingDescription}</i>
                    </Typography>
                  }
                  <Typography component="div" variant="caption">{service.timeSlots !== undefined ? service.timeSlots.reduce((sum, slot) => sum + Number(slot.duration), 0) : 'N/A'} minutes</Typography>
                  {service.tagIds !== undefined && service.tagIds.length > 0 &&
                    <Grid container style={{ flexGrow: 1 }} spacing={2}>
                      {renderServiceTags(service.tagIds)}
                    </Grid>
                  }
                  {!isBigScreen &&
                    <Box>
                      <Badge
                        badgeContent={props.selectedServices.filter(s => s._id === service._id).length}
                        color="primary"
                      >
                        <ButtonGroup size="small">
                          <Button
                            edge="end"
                            color="error"
                            variant="outlined"
                            size="small"
                            sx={{ minWidth: 0 }}
                            onClick={() => props.handleRemoveService(service._id)}
                            disabled={props.selectedServices.filter(s => s._id === service._id).length === 0}
                          >
                            <RemoveIcon />
                          </Button>
                          <Button edge="end" color="primary" variant="outlined" size="small" sx={{ minWidth: 0 }} onClick={() => props.handleSelectService(service._id)}>
                            <AddIcon />
                          </Button>
                        </ButtonGroup>
                      </Badge>
                    </Box>
                  }
                </Stack>
              }
            />
          </ListItem>
          {index !== props.services.length - 1 && <Divider component="li" />}
        </React.Fragment>
      )
    })
  }

  const renderBundle = () => {
    return props.bundles.map((bundle, index) => {
      return (
        <React.Fragment key={bundle._id}>
          <ListItem
            key={bundle._id}
            secondaryAction={isBigScreen &&
              <Badge
                badgeContent={props.selectedServices.filter(s => s.bundleName === bundle.bundleName).length / bundle.servicesInBundle.length}
                color="primary"
              >
                <ButtonGroup size="small">
                  <Button
                    edge="end"
                    color="error"
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: 0 }}
                    onClick={() => props.handleRemoveBundle(bundle)}
                    disabled={props.selectedServices.filter(s => s.bundleName === bundle.bundleName).length === 0}
                  >
                    <RemoveIcon />
                  </Button>
                  <Button edge="end" color="primary" variant="outlined" size="small" sx={{ minWidth: 0 }} onClick={() => props.handleSelectBundle(bundle)}>
                    <AddIcon />
                  </Button>
                </ButtonGroup>
              </Badge>
            }
          >
            <ListItemText
              sx={{ mr: 8 }}
              primary={
                <>{bundle.bundleName} <Chip icon={<Icon icon="fluent:box-multiple-20-regular" />} label="Bundle" size="small" component="span" /></>
              }
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <Stack spacing={0.25}>
                  {bundle.description !== undefined && bundle.description !== '' &&
                    <Typography
                      component="span"
                      variant="caption"
                      color="textPrimary"
                      style={{ display: 'block' }}
                    >
                      <i>{bundle.description}</i>
                    </Typography>
                  }
                  {bundle.tagIds !== undefined && bundle.tagIds.length > 0 &&
                    <Grid container style={{ flexGrow: 1 }} spacing={2}>
                      {renderServiceTags(bundle.tagIds)}
                    </Grid>
                  }
                  {!isBigScreen &&
                    <Box>
                      <Badge
                        badgeContent={props.selectedServices.filter(s => s.bundleName === bundle.bundleName).length / bundle.servicesInBundle.length}
                        color="primary"
                      >
                        <ButtonGroup size="small">
                          <Button
                            edge="end"
                            color="error"
                            variant="outlined"
                            size="small"
                            sx={{ minWidth: 0 }}
                            onClick={() => props.handleRemoveBundle(bundle)}
                            disabled={props.selectedServices.filter(s => s.bundleName === bundle.bundleName).length === 0}
                          >
                            <RemoveIcon />
                          </Button>
                          <Button edge="end" color="primary" variant="outlined" size="small" sx={{ minWidth: 0 }} onClick={() => props.handleSelectBundle(bundle)}>
                            <AddIcon />
                          </Button>
                        </ButtonGroup>
                      </Badge>
                    </Box>
                  }
                </Stack>
              }
            />
          </ListItem>
          {index !== props.bundles.length - 1 && <Divider component="li" />}
        </React.Fragment>
      )
    })
  }

  return (
    <Accordion
      variant="outlined"
      expanded={props.expanded}
      onChange={props.onExpandChange}
      TransitionProps={{
        style: { maxHeight: 300, overflowY: 'scroll' },
        unmountOnExit: true,
        mountOnEnter: true
      }}
    >
      <AccordionSummary>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">{props.category.name}</Typography>
          <Stack spacing={1}>
            <Chip
              label={props.services.length + props.bundles.length}
              size="small"
              variant="outlined"
            />
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <List dense disablePadding>
          {renderService()}
          {renderBundle()}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}

export default CategoryCard